<template>
  <div class="myTracksBox">
    <Introduction :basicInfo="userInfo" @submitClick="submitClick" />
  </div>
</template>

<script>
import Introduction from '@/components/userInfo/introduction'
import {myInfo} from '@/api/user/user'
export default {
  name: 'myTracks',
  components: {
    Introduction
  },
  data() {
    return {
      userInfo: {}
    }
  },
  mounted() {
    this.myUser()
  },
  methods: {
    submitClick() {
      this.myUser()
    },
    myUser() {
      myInfo().then(res => {
        this.userInfo = res.data
        localStorage.setItem('userInfo', JSON.stringify(res.data));
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .myTracksBox{
    width: 100%;
    background: #fff;
    margin-top: 12px;
  }
</style>
